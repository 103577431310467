<template>
	<div class="fragementBase">
		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">客户管理</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">方案评论</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form class="wf" ref="searchForm" :model="searchForm" :inline="true">
					<el-form-item label="文章名称">
						<el-input v-model="searchForm.name" clearable size="small" placeholder="请填写文章名称" />
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="small" @click="doSearch"><i class="fa fa-search"></i>查询
						</el-button>
						<el-button type="success" size="small" @click="newComment"><i class="fa fa-plus"></i>新增文章
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<!-- 最新激活记录 -->
		<div class="pdding20 bgffffff radius5 overflow mgtop10">

			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">数据展示</span>
			</div>

			<el-table :data="commentList" :stripe="true" border class="mgtop10">
				<el-table-column prop="subTime" label="发布日期" width="100">
					<template slot-scope="scope">
						{{scope.row.subTime.substring(0,10)}}
					</template>
				</el-table-column>
				<el-table-column prop="nickname" label="评论人" align="center"></el-table-column>
				<el-table-column prop="name" label="文章内容" width="300"></el-table-column>
				<el-table-column prop="reply" label="评论"></el-table-column>
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="24">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showComment(scope.$index)"><i class="fa fa-edit"></i>详情</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="commentList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>

		</div>


		<!-- 弹出层 -->
		<el-dialog :close-on-click-modal="false" title="文章信息" :visible.sync="dialogVisible" width="800px">
			<div class="wf">
				<!-- -->
				<el-form class="wf" ref="commentForm" :model="commentForm" label-width="100px" :rules="rules">

					<el-row :gutter="10">
						<el-col :span="24">
							<el-form-item label="评论标题" prop="name">{{commentForm.name}}</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="评论内容" prop="reply">{{commentForm.reply}}</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-col v-for="pic in commentForm.pics" span="6">
								<img style="width: 100px;height: 100px;" :src="pic.url">
							</el-col>
						</el-col>
						<el-col :span="24">
							<el-form-item label="我的跟评" prop="toreply">
								<el-input type="textarea" :rows="4" v-model="commentForm.toreply" placeholder="请填写跟评">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>

				</el-form>
				<!-- -->
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishComment">保存</el-button>
			</span>
		</el-dialog>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		data() {
			return {
				imgUploadUrl: this.$http.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				commentForm: {},
				commentList: [],
				rules: {
					opinion: [{
							required: true,
							message: '请填写意见/问题',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 100,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					tag: [{
						required: true,
						message: '请填写意见/问题标签',
						trigger: 'blur'
					}]
				},
			}
		},
		computed: {
			...mapGetters([
				'principal'
			])
		},
		methods: {
			doSearch() {
				this.pageChange(1);
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getComments();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getComments();
			},
			newComment() {
				this.commentForm = {
					toreply: ""
				};
				this.dialogVisible = true;
			},
			getComments() {
				this.$http.request(this, this.$apis.material.commentList, this.searchForm, false, res => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.pics = JSON.parse(item.pics);
					})
					this.commentList = res.data;
				});
			},
			publishComment() {

				this.$refs['commentForm'].validate((valid) => {
					if (valid) {
						var commentForm = Object.assign({}, this.commentForm);
						commentForm.comment = commentForm.serial;
						commentForm.reply = commentForm.toreply;
						commentForm.pics = "[]";
						commentForm.serial = "";
						commentForm.updateTime = "";

						this.$http.request(this, this.$apis.material.manageDealComment, commentForm, true, res => {
							if (res.data != null) {
								this.commentList.unshift(res.data);
							}

							this.dialogVisible = false;
						});
					}
				});
			},
			showComment(index) {
				var commentForm = this.commentList[index];
				this.commentForm = commentForm;
				this.dialogVisible = true;
			}
		},
		mounted() {

		},
		created() {
			this.getComments();
		}
	}
</script>
